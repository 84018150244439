<template>
  <div class="card">
    <div class="card-header">
      <div class="float-left">{{$t('generic-str.key-word')}}</div>
      <div class="float-right">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              v-modal="{ target: 'create-key-modal' }"
              class="btn btn-success"
            >
              <i class="icon dripicons-plus"></i>
              <span class="btn-trim">{{$t('generic-str.lbl-add-new')}}</span>
            </button>
            <button
              class="btn btn-danger btn-remove border-radius-right"
              :class="{
                'qt-loader qt-loader-mini qt-loader-right': isSending,
              }"
              @click="deleteMultiple"
              v-bind:disabled="selectedKeywords.length == 0 || isSending"
            >
              <i class="icon dripicons-trash color-white"></i>
              <span class="btn-trim">{{$t('generic-str.delete')}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body block-el p-0">
      <div class="responsive-table">
        <div
          v-if="!keywords.length && fetched"
          class="text-center table-placeholder"
        >
          <i class="zmdi zmdi-spellcheck zmdi-hc-fw no-item"></i>
          <h5 class="card-title m-t-20">Nenhuma palavra chave cadastrada</h5>
        </div>
        <div
          class="custom-control custom-checkbox text-right hide show-m all-check-mobile"
        >
          <input
            v-model="selectedKeywords"
            type="checkbox"
            class="custom-control-input"
            :id="'blacklist-check'"
          />
          <label
            class="custom-control-label v-align-top"
            :for="'blacklist-check'"
          ></label>
        </div>
        <table v-if="fetched" class="table">
          <thead v-if="keywords.length" class="bg-light">
            <tr>
              <th class="w-60" scope="col">
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="selectAllKeywords"
                    type="checkbox"
                    class="custom-control-input"
                    id="check-all-keywords"
                  />
                  <label
                    class="custom-control-label v-align-top"
                    for="check-all-keywords"
                  ></label>
                </div>
              </th>
              <th scope="col" class="text-left">{{$tc('generic-str.name', 1)}}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(keyword, i) in keywords" :key="keyword.id">
              <td data-label="">
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="selectedKeywords"
                    type="checkbox"
                    class="custom-control-input"
                    :id="'blacklist-check' + i"
                    :value="keyword"
                  />
                  <label
                    class="custom-control-label v-align-top"
                    :for="'blacklist-check' + i"
                  ></label>
                </div>
              </td>
              <td data-label="Nome: " class="text-left m-text-right">
                <div>{{ keyword.word }}</div>
              </td>
              <td data-label="" class="text-right">
                <div class="dropdown" style="margin-right: 5px">
                  <a
                    role="button"
                    class="dropdown-action"
                    :to="'#keyword-dropdown-' + i"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="icon dripicons-gear text-success"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    :id="'keyword-dropdown-' + i"
                    x-placement="bottom-end"
                    style=""
                  >
                    <a
                      class="dropdown-item"
                      v-modal="{ target: 'key-modal', data: keyword }"
                      >{{$t('generic-str.edit')}}</a
                    >
                    <a class="dropdown-item" @click="remove(keyword)"
                      >{{$t('generic-str.remove')}}</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="qt-block-ui relative" style="padding: 120px" />
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'KeywordsTable',
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      paginate: {},
      keywords: [],
      selectedKeywords: [],
      form: {
        page: 1,
        channel: 'rcs',
      },
      pages: 1,
    };
  },
  computed: {
    selectAllKeywords: {
      get() {
        if (this.keywords) {
          return this.selectedKeywords.length === this.keywords.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.keywords.forEach((keyword) => {
            selected.push(keyword);
          });
        }

        this.selectedKeywords = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      SmsService.getKeylist({ channel: 'rcs' }, page)
        .then(
          (response) => {
            this.keywords = response.data;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    remove(keyword) {
      Swal.fire({
        title: `Remover palavra-chave "${keyword.word}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          SmsService.deleteKeyword(keyword.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Palavra-chave removida com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteMultiple() {
      Swal.fire({
        title: 'Remover palavras-chave?',
        text: 'Essa ação não poderá ser desfeita?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedKeywords.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteMultipleKeywords({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.fetch();
              this.isSending = false;
              this.selectedKeywords = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 768px) and (max-width: 992px) {
  .btn-trim {
    display: none;
  }
}
.card-body {
  min-height: 200px !important;
}
.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}
</style>
