<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6 col-lg-8">{{$tc('generic-str.lbl-number', 2)}}</div>
        <div class="col-md-6 col-lg-4 col-sm-12">
          <button
            class="btn btn-danger btn-remove float-right"
            :class="{
              'qt-loader qt-loader-mini qt-loader-right': isSending,
            }"
            @click="deleteMultiple"
            v-bind:disabled="selectedOpted.length == 0 || isSending"
          >
            <i class="icon dripicons-trash color-white"></i>{{$t('generic-str.delete')}}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body block-el p-0">
      <div class="responsive-table">
        <div
          v-if="!optouts.length && fetched"
          class="text-center table-placeholder"
        >
          <i class="zmdi zmdi-block zmdi-hc-fw no-item"></i>
          <h5 class="card-title m-t-20">{{$t('warnings.optouts.none')}}</h5>
          <p>{{$t('warnings.optouts.register')}}</p>
        </div>
        <div
          class="custom-control custom-checkbox text-right hide show-m all-check-mobile"
        >
          <input
            v-model="selectAllTemplates"
            type="checkbox"
            class="custom-control-input"
            id="customCheckTAll"
          />
          <label
            class="custom-control-label v-align-top"
            for="customCheckTAll"
          ></label>
        </div>
        <table v-if="fetched" class="table">
          <thead v-if="optouts.length" class="bg-light">
            <tr>
              <th class="w-60" scope="col">
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="selectAllTemplates"
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheckTAll"
                  />
                  <label
                    class="custom-control-label v-align-top"
                    for="customCheckTAll"
                  ></label>
                </div>
              </th>
              <th scope="col" class="text-left m-text-right">{{$tc('generic-str.lbl-number', 1)}}</th>
              <th scope="col" class="text-left m-text-right">{{$t('generic-str.date')}}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(optout, i) in optouts" :key="optout.id">
              <td data-label="">
                <div class="custom-control custom-checkbox">
                  <input
                    v-model="selectedOpted"
                    type="checkbox"
                    class="custom-control-input"
                    :id="'optout-check' + i"
                    :value="optout"
                  />
                  <label
                    class="custom-control-label v-align-top"
                    :for="'optout-check' + i"
                  ></label>
                </div>
              </td>
              <td data-label="Número: " class="text-left m-text-right">
                <div class="max-200" v-tooltip.top="`${optout.mobile_number}`">
                  {{ optout.mobile_number }}
                </div>
              </td>
              <td data-label="Data: " class="text-left m-text-right">
                {{ optout.created_at | formatDate }}
              </td>
              <td data-label="" class="text-right">
                <div class="dropdown" style="margin-right: 15px">
                  <a
                    role="button"
                    class="dropdown-action"
                    :to="'#menu-dropdown-' + i"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="icon dripicons-gear text-success"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    :id="'menu-dropdown-' + i"
                    x-placement="bottom-end"
                    style=""
                  >
                    <a class="dropdown-item" @click="remove(optout)">{{$t('generic-str.remove')}}</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="qt-block-ui relative" style="padding: 120px" />
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'OptedCard',
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      paginate: {},
      optouts: [],
      selectedOpted: [],
      form: {
        page: 1,
        channel: 'rcs',
      },
      pages: 1,
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.optouts) {
          return this.selectedOpted.length === this.optouts.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.optouts.forEach((optout) => {
            selected.push(optout);
          });
        }

        this.selectedOpted = selected;
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.fetched = false;
      this.form.page = page;
      this.form.channel = 'rcs';
      SmsService.fetchOptouts(this.form).then(
        (response) => {
          this.fetched = true;
          this.optouts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(opted) {
      Swal.fire({
        title: `Remover número "${opted.mobile_number}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          SmsService.deleteOptout(opted.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Número removido com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteMultiple() {
      Swal.fire({
        title: this.$t('warnings.optouts.remove-number'),
        text: this.$t('warnings.optouts.text-number'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedOpted.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteAllOptouts({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.fetch();
              this.isSending = false;
              this.selectedOpted = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}
</style>
